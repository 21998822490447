.gallery {
    // .gallery__wrap
    &__wrap {
        @extend .history__wrap;
    }
    // .gallery__h2
    &__h2 {
        @extend .history__h2;
    }
    // .gallery__row
    &__row {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        @media (min-width: 600px) {
            margin: 0 -10px;
            overflow: hidden;
        }
    }
    // .gallery__column
    &__column {
        flex: 1 1 100%;
        margin-bottom: 15px;
        @media (min-width: 600px) {
            flex: 1 1 50%;
            padding: 0 10px;
        }
        @media (min-width: 900px) {
            flex: 1 1 33.333%;
        }
    }
    // .gallery__img
    &__img {
        position: relative;
        padding-bottom: 50%;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
