.wrapper {
    margin-top: 115px;
    max-width: 100%;
    min-height: 100%;
    min-width: 320px;
    overflow: hidden;
}

.h1 {
    font-family: $font_family_Montserrat;
    font-size: 30px;
    line-height: calc(37 / 30 * 100%);
    font-weight: 800;
}
.h2 {
    font-family: $font_family_Montserrat;
    font-weight: 800;
    font-size: 24px;
    line-height: calc(29 / 24 * 100%);
}
.line {
    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
    }
}
.header-image {
    img {
        display: block;
        margin-top: 10px;
        height: 3px;
    }
}
.text {
    font-family: $font_family_Lato;
    font-size: 16px;
    line-height: calc(22 / 16 * 100%);
    span {
        white-space: nowrap;
    }
}

.btn-transparent {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 52px;
    padding: 24px;
    border-width: 2px;
    border-radius: 26px;
    border-style: solid;
    font-size: 14px;
}

.item-hover {
    position: relative;
    cursor: pointer;
    transition: color 0.3s ease-in;

    &:after {
        @extend .line;
        background-color: transparent;
        transition: background-color 0.3s ease-in;
    }
    &:hover {
        color: $color-blue;
        &:after {
            background-color: $color-red;
        }
    }
}

.fieldset {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 52px;
    grid-gap: 20px;
    margin-bottom: 30px;
    border: none;
}

.fieldset-field {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 24px;
    border-width: 2px;
    border-style: solid;
    border-color: $color-blue;
    background-color: $color-light_gray;
    border-radius: 0;
    font-family: $font_family_Montserrat;
    color: $color-black;
    label {
        display: none;
    }
    input, textarea, select {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    input, select {
        padding: 0 20px;
    }
    textarea {
        padding: 15px 24px;
        resize: none;
    }
    ul {
        position: absolute;
        bottom: -20px;
        li {
            font-size: 0.7em;
            color: $color-red;
            animation: hide 1s ease-in-out 5s normal forwards;
        }
    }
    @keyframes hide {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
}
.line-blue-small {
    position: relative;
    &::after {
        content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOTYiIGhlaWdodD0iMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsPSIjMDA2NmIzIiBkPSJNMCAwaDUwLjY0djNIMHptNTUuNDMgMGgyOC43NDF2M0g1NS40M3ptMzYuMjY5IDBoNC4xMDZ2M2gtNC4xMDZ6Ii8+PC9zdmc+);
        position: absolute;
    }
}
// наследуйте этот класс для блоков заполняемых через админку
.admin {
    h1 {
        @extend .h1;
    }
    h2 {
        @extend .h2;
    }
    p {
        @extend .text;
    }
    ul:not(.required.errors) {
        margin-left: 70px;
        li {
            @extend .line-blue-small;
            margin-bottom: 20px;
            font-family: $font_family_Lato;
            font-weight: 800;
            font-size: 18px;
            line-height: calc(22 / 18 * 100%);
            color: $color-black;
            &::after {
                bottom: -10px;
                left: 0;
                z-index: 1;
            }
            a {
                position: relative;
                color: inherit;
                text-decoration: transparent;
                transition: all 0.3s ease-in;
                z-index: 2;
                &:hover {
                    color: $color-blue;
                }
            }
        }
    }
    a {
        color: $color-blue;
        text-decoration: underline;
        transition: all 0.3s ease-in;
        &:hover {
            color: $color-red;
        }
    }
}
