.banner {
    // .banner__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto 90px;
        padding-top: 40px;
    }
    // .banner__row
    &__row {
        display: flex;
        overflow: hidden;
        @media(max-width:767px) {
            flex-direction: column;
        }
        @media(min-width:768px) {
            height: 300px;
        }
    }
    // .banner__column
    &__column {
        // .banner__column_image
        &_image {
            position: relative;
            @media(max-width:767px) {
                padding-bottom: 50%;
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            @media(min-width:768px) {
                flex: 0 1 500px;
            }
            @media(min-width:900px) {
                &:before {
                    content: "";
                    position: absolute;
                    right: -20px;
                    bottom: 0;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 0 375px 115px;
                    border-color: transparent transparent $color-white transparent;
                    z-index: 4;
                }
            }
        }
        // .banner__column_content
        &_content {
            position: relative;
            padding: 25px 20px;
            color: $color-white;
            background: $color-blue;
            *:last-child {
                margin-bottom: 0;
            }
            @media(min-width:768px) {
                padding: 50px 30px 45px;
                flex: 1 1 500px;
            }
            @media(min-width:900px) {
                &:before {
                    content: "";
                    position: absolute;
                    left: -75px;
                    bottom: 0;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 0 300px 95px;
                    border-color: transparent transparent $color-blue transparent;
                    z-index: 5;
                }
            }
        }
    }
    // .banner__h1
    &__h1 {
        @extend .h1;
        @extend .header-image;
        margin-bottom: 30px;
    }
    // .banner__text
    &__text {
        @extend .text;
        margin-bottom: 15px;
    }
    // .banner__link
    &__link {
        @extend .text;
        text-decoration: underline;
        transition: all 0.3s ease-in;
        &:hover {
            text-decoration: transparent;
        }
    }
}
