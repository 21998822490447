.footer {
    background: $color-gray;
    padding-top: 50px;
    padding-bottom: 40px;
    // .footer__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
    }
    // .footer__row
    &__row {
        margin-bottom: 15px;
        border-bottom: 1px solid #606060;
        @media (min-width: 1200px) {
            display: flex;
        }
    }
    // .footer__column
    &__column {
        margin-bottom: 30px;
        // .footer__column_left
        &_left {
            @media (min-width: 1200px) {
                flex: 0 1 400px;
                display: flex;
                margin-right: 140px;
            }
        }
        // .footer__column_rignt
        &_rignt {
            display: flex;
            @media (max-width: 599px) {
                flex-wrap: wrap;
            }
            @media (min-width: 900px) {
                justify-content: space-between;
                flex-direction: column;
                flex: 1 1 50%;
                margin-bottom: 0;
            }
            @media(min-width:1200px) {
                margin-bottom: 40px;
            }
        }
    }
    // .footer__logo
    &__logo {
        text-align: center;
        margin-bottom: 20px;
        @media (min-width: 1200px) {
            flex: 0 0 140px;
            margin-right: 30px;
            img {
                width: 100%;
            }
        }
    }
    // .footer__menu
    &__menu {
        // flex: 1 1 auto;
    }
    // .footer__list
    &__list {
        display: flex;
        @media(max-width:1199px) {
            flex-wrap: wrap;
            justify-content: space-between;
        }
        @media(min-width:1200px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }
    // .footer__item
    &__item {
        @extend .item-hover;
        margin-bottom: 25px;
        margin-right: 15px;
        &:last-child {
            margin-right: 0;
        }
    }
    // .footer__link
    &__link {
        font-size: 12px;
        line-height: calc(15 / 12 * 100%);
        letter-spacing: 0.6px;
        &_svg {
            @extend .header__menu-link_svg;
        }
    }
    // .footer__side
    &__side {
        display: flex;
        font-family: $font_family_Lato;
        font-size: 12px;
        line-height: calc(18 / 12 * 100%);
        @media (max-width: 899px) {
            flex-direction: column;
            align-items: center;
            text-align: center;
        }
    }
    // .footer__policy
    &__policy {
        @media (max-width: 899px) {
            margin-bottom: 20px;
        }
        @media (min-width: 900px) {
            flex: 0 1 565px;
        }
    }
    // .footer__creator
    &__creator {
        @media (min-width: 900px) {
            flex: 1 1 auto;
            text-align: right;
        }
    }
    // .footer__moow
    &__moow {
        transition: all 0.3s ease-in;
        &:hover {
            color: $color-red;
            text-decoration: underline;
        }
    }
}

.row-list {
    flex: 1 1 100%;
    display: flex;
    @media (max-width: 599px) {
        margin-bottom: 25px;
    }
    @media (max-width: 899px) {
        flex-direction: column;
        align-items: flex-start;
    }
    @media (min-width: 900px) {
        margin-bottom: 50px;
        justify-content: space-between;
        border-bottom: 1px solid #606060;
    }
    @media(min-width:1200px) {
        margin-bottom: 0;
        flex: 0 1 auto;
    }
    // .row-list__item
    &__item {
        @extend .item-hover;
        margin-bottom: 15px;
        // padding-bottom: 15px;
        margin-right: 10px;
        // margin-right: 53px;
        &:last-child {
            margin-right: 0;
        }
        @media (min-width: 900px) {
            margin-bottom: 0;
        }
    }
    // .row-list__link
    &__link {
        display: inline-block;
        padding-bottom: 5px;
        font-size: 14px;
        line-height: calc(17 / 14 * 100%);
        letter-spacing: 0.6px;
        @media (min-width: 900px) {
            padding-bottom: 15px;
        }
    }
}

.bottom-list {
    flex: 1 1 100%;
    display: flex;
    @media (max-width: 599px) {
        align-items: center;
    }
    @media (min-width: 600px) {
        align-items: flex-end;
    }
    @media (max-width: 899px) {
        flex-direction: column-reverse;
    }
    @media (min-width: 900px) {
        justify-content: space-between;
    }
    @media(min-width:1200px) {
        border-bottom: 1px solid #606060;
        flex: 0 1 auto;
    }
    // .bottom-list__item
    &__item {
        &_hover {
            @extend .item-hover;
        }
        padding-bottom: 5px;
        margin-bottom: 10px;
        // padding-bottom: 10px;
        font-size: 12px;
        line-height: calc(15 / 12 * 100%);
        letter-spacing: 0.6px;
        @media (min-width: 900px) {
            padding-bottom: 15px;
            margin-bottom: 0;
        }
    }
    // .bottom-list__language
    &__language {
        display: flex;
        .bottom-list__item {
            &:first-child {
                margin-right: 20px;
            }
        }
    }
    // .bottom-list__link
    &__link {
        svg {
            color: $color-dark_gray;
            transition: all 0.3s ease-in;
            &:hover {
                color: red;
            }
        }
    }
    // .bottom-list__social-box
    &__social-box {
        margin-bottom: 12px;
        .social-box__link {
            &:not(:last-child) {
                margin-right: 20px;
            }
        }
    }
}

.social-box {
    display: flex;
    align-items: center;
    &__link {
        @extend .bottom-list__link;
    }
    .icon-vk {
        height: 21px;
        width: 29px;
    }
    .icon-linkedin {
        width: 28px;
        height: 30px;
    }
}
