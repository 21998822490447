.header {
    background: $color-light_gray;
    position: fixed;
    z-index: 100;
    width: 100%;
    margin-top: -115px;
    // .header__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
        display: flex;
        align-items: center;
        height: 115px;
    }
    // .header__logo-box
    &__logo-box {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
    }
    // .header__logo-img
    &__logo-img {
        margin-right: 16px;
        max-width: unset;
    }
    // .header__logo-text
    &__logo-text {
        max-width: 190px;
        font-size: 13px;
        @media(max-width:399px) {
            margin-right: 10px;
        }
    }
    // .header__burger
    &__burger {
        display: none;

        @media (max-width: 899px) {
            display: block;
            z-index: 10;
        }
        position: relative;
        width: 30px;
        height: 30px;

        span {
            position: absolute;
            top: 50%;
            width: 30px;
            height: 2px;
            background-color: #000;
            transition: all 0.1s ease 0s;

            &::before {
                content: "";
                position: absolute;
                top: 10px;
                width: 30px;
                height: 2px;
                background-color: #000;
                transition: all 0.3s ease 0s;
            }

            &::after {
                content: "";
                position: absolute;
                top: -10px;
                width: 30px;
                height: 2px;
                background-color: #000;
                transition: all 0.3s ease 0s;
            }
        }

        &_active {
            span {
                width: 0;

                &::before {
                    transform: rotate(-45deg);
                    top: 0;
                }

                &::after {
                    transform: rotate(45deg);
                    top: 0;
                }
            }
        }
    }
    // .header__menu
    &__menu {
        @media (min-width: 900px) {
            display: block;
        }
        @media (max-width: 899px) {
            display: none;
        }
        &_active {
            display: block;
            position: fixed;
            top: 115px;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $color-light_gray;
            z-index: 20;
            overflow-y: auto;
            .header__menu-list {
                flex-direction: column;
                align-items: flex-start;
                padding: 20px 20px 115px;
            }
        }
    }
    // .header__menu-list
    &__menu-list {
        display: flex;
        @media (max-width: 899px) {
            padding: 20px;
        }
    }
    // .header__menu-item
    &__menu-item {
        @media (min-width: 900px) {
            white-space: nowrap;
            margin-right: 25px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    // .header__menu-link
    &__menu-link {
        display: inline-block;
        @extend .item-hover;
        padding-bottom: 10px;
        font-size: 13px;

        @media (max-width: 899px) {
            font-size: 24px;
            margin-bottom: 30px;
        }
        // .header__menu-link_svg
        &_svg {
            display: inline-flex;
            align-items: center;
            img {
                margin-left: 5px;
            }
        }
    }
}
