.info {
    background: $color-light_gray;
    padding: 40px 0 0;
    // .info__wrap
    &__wrap {
        @include x-container(1054px);

        margin: 0 auto;
        @media(min-width:600px) {
            margin: 0 auto;
        }
        @media(min-width:900px) {
            margin: 0 auto;
        }
        @media(min-width:1200px) {
            margin: 0 auto;
        }
    }
    // .info__row
    &__row {
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        &_top {
            @media (min-width: 600px) {
                margin: 0 -15px;
            }
            @media (min-width: 900px) {
                margin: 0 -30px;
            }
            @media (min-width: 1200px) {
                margin: 0 -56px;
            }
        }
    }
    // .info__column
    &__column {
        flex: 1 1 100%;
        @media (min-width: 600px) {
            flex: 0 1 50%;
            padding: 0 15px;
        }
        @media (min-width: 900px) {
            flex: 0 1 calc(100% / 3);
        }
        margin-bottom: 30px;
        // .info__column_info
        @media (min-width: 900px) {
            padding: 0 30px;
        }
        @media (min-width: 1200px) {
            padding: 0 56px;
        }
        &_info {
        }
        // .info__column_director
        &_director {
            @media (max-width: 899px) {
                order: 5;
                flex: 1 1 100%;
            }
            @media (min-width: 900px) {
                flex: 1 1 calc((100% - 30px) / 3 * 2);
            }
        }
        // .info__column_side
        &_side {
            flex: 1 1 calc((100% - 30px) / 3);
            display: flex;
            flex-direction: column-reverse;
            @media (min-width: 900px) {
                flex-direction: column;
                justify-content: space-around;
            }
        }
    }
    // .info__title
    &__title {
        position: relative;
        display: block;
        margin-bottom: 12px;
        font-size: 64px;
        font-weight: 800;
        line-height: calc(78 / 64 * 100%);
        color: $color-blue;
        &:after {
            @extend .line;
            background-color: $color-red;
        }
    }
    // .info__text
    &__text {
        @extend .text;
    }
    // .info__card
    &__card {
        display: flex;
        color: $color-white;
        background: $color-blue;
        @media (max-width: 599px) {
            flex-direction: column;
        }
        @media (min-width: 900px) {
            margin-right: -26px;
            overflow: hidden;
        }
    }
    // .info__img
    &__img {
        position: relative;
        padding-bottom: 73%;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        @media (min-width: 600px) {
            flex: 0 0 50%;
            padding-bottom: 36.5%;
        }
        @media (min-width: 900px) {
            &:after {
                content: "";
                position: absolute;
                right: 0;
                bottom: 0;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 0 328px 90px;
                border-color: transparent transparent $color-white transparent;
            }
        }
    }
    // .info__description
    &__description {
        flex: 1 1 auto;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 30px 40px 20px;
        font-family: $font_family_Lato;
        font-size: 16px;
        line-height: calc(20 / 16 * 100%);
        span {
            white-space: nowrap;
        }
        @media (min-width: 900px) {
            &:after {
                content: "";
                position: absolute;
                left: -72px;
                bottom: 0;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 0 328px 90px;
                border-color: transparent transparent $color-blue transparent;
            }
        }
    }
    // .info__link
    &__link {
        @extend .header-image;

        position: relative;
        padding-bottom: 7px;
        margin-top: 20px;
        margin-bottom: 50px;
        font-family: $font_family_Lato;
        font-weight: 800;
        font-size: 18px;
        line-height: calc(20 / 18 * 100%);
        color: $color-blue;

        &:after {
            @extend .line;
            background-color: $color-red;
        }
        @media (min-width: 600px) {
            margin-top: 80px;
            margin-left: calc(-100% - 30px);
            width: 100%;
        }
        @media (max-width: 899px) {
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
        }
        @media (min-width: 900px) {
            margin: 0;
        }
    }
}
