.calendar {
    // .calendar__wrap
    &__wrap {
        @include x-container();
        margin: 50px auto 20px;
    }
    // .calendar__row
    &__row {
        display: flex;
        @media (max-width: 1199px) {
            flex-direction: column;
        }
    }
    // .calendar__column
    &__column {
        // .calendar__column_list
        &_list {
            @media (max-width: 1199px) {
                margin-bottom: 50px;
            }
            @media (min-width: 1200px) {
                flex: 1 1 auto;
                margin-right: 20px;
            }
        }
        // .calendar__column_calendar
        &_calendar {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            @media (max-width: 1199px) {
                justify-content: center;
            }
            @media (min-width: 1200px) {
                flex: 1 0 600px;
            }
            @media (max-width: 899px) {
                flex-direction: column;
            }
        }
    }
    // #calendar
    #calendar {
        @media(max-width:399px) {
            width: 100%;
        }
        @media(max-width:899px) {
            align-self: center;
        }
    }
    // .calendar__list-name
    &__list-name {
        margin-bottom: 40px;
        font-size: 18px;
        line-height: calc(22 / 18 * 100%);
        font-weight: 700;
    }
    // .calendar__list
    &__list {
    }
    // .calendar__item
    &__item {
        margin-bottom: 25px;
        &:last-child {
            margin-bottom: 0;
        }
        // .calendar__item_past
        &_past {
            color: $color-dark_gray;
        }
        // .calendar__item_future
        &_future {
            color: $color-blue;
        }
        // .calendar__item_nearest
        &_nearest {
            color: $color-red;
        }
    }
    // .calendar__list-date
    &__list-date {
        font-size: 14px;
        font-family: $font_family_Lato;
        color: #606060;
    }

    // .calendar__list-text
    &__list-text {
        font-size: 14px;
        font-family: $font_family_Lato;
        color: #606060;
        line-height: 1.3rem;
        margin-top: 10px;
    }

    // .calendar__list-module
    &__list-module {
        font-size: 14px;
        font-family: $font_family_Lato;
        color: #cacaca;
        line-height: 1.3rem;
        margin-top: 10px;
    }

    // .calendar__list-event
    &__list-event {
        display: flex;
        flex-direction: column;
        font-family: $font_family_Lato;
        font-size: 24px;
        line-height: calc(32 / 24 * 100%);
        font-weight: 500;
        img {
            display: block;
            margin-top: 5px;
        }
    }
    // .calendar__link
    &__link {
        img {
            @media(max-width:499px) {
                display: none;
            }
        }
    }
    // .calendar__info
    &__info {
        margin-top: 40px;
        @media(min-width:900px) {
            margin-left: 40px;
        }
        @media (max-width: 899px) {
            display: flex;
            justify-content: center;
        }
        @media(max-width:499px) {
            flex-direction: column;
        }
    }
    // .calendar__type
    &__type {
        display: flex;
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
        @media(max-width:499px) {
            align-items: center;
        }
    }
    // .calendar__color
    &__color {
        flex: 0 0 35px;
        height: 35px;
        border-radius: 50%;
        margin-right: 15px;
        &_red {
            background-color: $color-red;
        }
        &_blue {
            background-color: $color-blue;
        }
        &_gray {
            background-color: $color-dark_gray;
        }
    }
    // .calendar__color-value
    &__color-value {
        font-size: 14px;
        line-height: calc(17 / 14 * 100%);
        font-weight: 700;
        color: $color-blue;
    }

    // plugin style
    &__body {
        position: relative;
    }
    &__picker {
        position: absolute;
        top: 0;
        left: 0;
        width: 180px;
        z-index: 2;
        background: $color-light_gray;
    }
    &__picker-month,
    &__picker-year {
        box-shadow: 0px 5px 10px 0px $color-dark_gray;
    }
    &__picker-month-option,
    &__picker-year-option {
        padding: 5px 10px;
        font-size: 18px;
        line-height: calc(22 / 18 * 100%);
        border-bottom: 1px solid $color-dark_gray;
        cursor: pointer;
    }
    &__days,
    &__weekdays {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        @media (min-width: 500px) {
            grid-template-columns: repeat(7, 45px);
        }
        grid-gap: 15px;
        text-align: center;
    }
    &__weekdays {
        margin-bottom: 15px;
    }
    &__weekday {
        font-size: 15px;
        color: #919191;
        @media(min-width:500px) {
            font-size: 22px;
            line-height: calc(31 / 22 * 100%);
        }
    }
    &__monthyear {
        margin-bottom: 12px;
    }
    &__month {
    }
    &__day {
        font-size: 16px;
        color: #49575d;
        cursor: pointer;
        border: 1px solid transparent;
        @media(min-width:500px) {
            font-size: 22px;
            line-height: calc(31 / 22 * 100%);
        }
    }
    &__day-text {
        display: block;
        width: 100%;
    }
    &__day-other {
        opacity: 0.7;
    }
    &__day-event {
        position: relative;
        .calendar__day-text {
            position: relative;
            color: $color-black;
            font-weight: 700;
            z-index: 2;
        }
        .calendar__day-bullet {
            position: absolute;
            right: 4px;
            bottom: -1px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            @media(min-width:500px) {
                width: 20px;
                height: 20px;
            }
        }
    }
    &__day-selected {
        border: 1px solid $color-blue;
    }
    &__month,
    &__year {
        display: inline-flex;
        align-items: center;
        margin-right: 15px;
        color: $color-blue;
        font-size: 18px;
        line-height: calc(22 / 18 * 100%);
        font-weight: 700;
        &::after {
            content: "";
            margin-left: 5px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6.9px 4px 0 4px;
            border-color: $color-blue transparent transparent transparent;
        }
    }
}
