.certificate {
    background-color: $color-blue;
    // .certificate__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
        padding-top: 55px;
        padding-bottom: 45px;
        @media(min-width:1200px) {
            margin: 90px auto 0;
        }
    }
    // .certificate__row
    &__row {
        display: flex;
        flex-wrap: wrap;
    }
    // .certificate__column
    &__column {
        flex: 1 1 100%;
        margin-bottom: 20px;
        // .certificate__column_info
        &_info {
            color: $color-white;
            @media(min-width:900px) {
                flex: 0 1 calc(50% - 30px);
                margin-right: 60px;
            }
        }
        // .certificate__column_image
        &_image {
            text-align: center;
            @media(min-width:900px) {
                flex: 0 0 calc(50% - 30px);
            }
            @media(min-width:1200px) {
                margin-top: calc(-55px - 90px);
            }
            img {
                background-color: $color-white;
            }
        }
    }
    // .certificate__h2
    &__h2 {
        @extend .h1;
        @extend .header-image;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 25px;
    }
    // .certificate__text
    &__text {
        @extend .text;
        margin-bottom: 25px;
    }
    // .certificate__link
    &__link {
        @extend .btn-transparent;
        border-color: $color-white;
        color: $color-white;
        transition: background 0.3s ease-in;
        &:hover {
            background: $color-white;
            color: $color-blue;
        }
    }
}
