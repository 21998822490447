.home {
    position: relative;
    padding-top: 80px;
    padding-bottom: 40px;
    margin-bottom: 100px;
    @media (min-width: 900px) {
        height: 600px;
    }
    // .home__wrap
    &__wrap {
        @include x-container();
        position: relative;
        margin: 0 auto;
        z-index: 3;
    }
    // .home__bg
    &__bg {
        @extend .offline__bg;
    }
    // .home__row
    &__row {
        display: flex;
    }
    // .home__column
    &__column {
        @extend .offline__column;
    }
    // .home__h2
    &__h2 {
        @extend .offline__h2;
    }
    // .home__text
    &__text {
        @extend .text;
        margin-bottom: 40px;
    }
    // .home__list
    &__list {
    }
    // .home__item
    &__item {
        display: flex;
        align-items: center;
        font-size: 18px;
        line-height: calc(22 / 18 * 100%);
        font-weight: 500;
    }
    // .home__img
    &__img {
        flex: 0 0 60px;
        margin-right: 5px;
        text-align: center;
        @media(min-width:600px) {
            flex: 0 0 75px;
        }
    }
}
