.offline {
    position: relative;
    padding-top: 80px;
    padding-bottom: 60px;
    @media (min-width: 900px) {
        height: 508px;
    }
    // .offline__bg
    &__bg {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        background: $color-white;
        @media(max-width:899px) {
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba($color: $color-black, $alpha: 0.7);
                z-index: 2;
            }
        }
        @media (min-width: 600px) {
            img {
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        @media (min-width: 900px) {
            width: calc(100% - 450px);
        }
        @media(min-width:1150px) {
            width: calc(50% + (1150px / 2 - 450px));
        }
    }
    // .offline__wrap
    &__wrap {
        @include x-container();
        position: relative;
        margin: 0 auto;
        z-index: 3;
        color: $color-white;
        @media(min-width:900px) {
            color: $color-black;
        }
    }
    // .offline__row
    &__row {
        display: flex;
    }
    // .offline__column
    &__column {
        color: $color-white;
        flex: 1 1 auto;
        @media(min-width:600px) {
            flex: 0 0 430px;
        }
        @media(min-width:900px) {
            color: $color-black;
        }
    }
    // .offline__h2
    &__h2 {
        @extend .h1;
        @extend .header-image;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 35px;
    }
    // .offline__text
    &__text {
        @extend .text;
        margin-bottom: 70px;
    }
    // .offline__link
    &__link {
        @extend .btn-transparent;
        transition: all 0.3s ease-in;
        margin-right: 20px;
        margin-bottom: 20px;
        &:last-child {
            margin-right: 0;
        }
        &_fill {
            border-color: $color-blue;
            background: $color-blue;
            color: $color-white;
            &:hover {
                background: transparent;
                color: $color-blue;
            }
        }
        &_empty {
            border-color: $color-red;
            background: $color-red;
            color: $color-white;
            &:hover {
                background: transparent;
                color: $color-red;
            }
        }
    }
}
