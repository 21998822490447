// ################################################################################
// Css variables
  // Padding, необходимый для контейнера (по-умолчанию)
  :root {
    --padding: 15px;
  }
// ################################################################################

// Sass variables
  // Ширина органичивающего контейнера с учетом padding'a (по-умолчанию)
  $wrap: 1150px;

// ################################################################################
// Контейнер (оболочка) - max-width и padding
@mixin x-container($max-width: $wrap, $padding: var(--padding)) {
  @if ($max-width and $padding) {
    max-width: calc(#{$max-width} + (#{$padding} * 2));
    padding: 0 #{$padding};
  } @else if (#{$max-width}) {
    max-width: calc(#{$max-width} + (var(--padding) * 2));
    padding: 0 var(--padding);
  } @else if (#{$padding}) {
    max-width: calc($wrap + (#{$padding} * 2));
    padding: 0 #{$padding};
  } @else {
    max-width: calc($wrap + (var(--padding) * 2));
    padding: 0 var(--padding);
  }
}