@font-face {
    font-family: 'Lato';
    src: url('Lato-Regular.eot');
    src: url('Lato-Regular.eot?#iefix') format('embedded-opentype'),
        url('Lato-Regular.woff2') format('woff2'),
        url('Lato-Regular.woff') format('woff'),
        url('Lato-Regular.ttf') format('truetype'),
        url('Lato-Regular.svg#Lato-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Lato';
    src: url('Lato-Medium.eot');
    src: url('Lato-Medium.eot?#iefix') format('embedded-opentype'),
        url('Lato-Medium.woff2') format('woff2'),
        url('Lato-Medium.woff') format('woff'),
        url('Lato-Medium.ttf') format('truetype'),
        url('Lato-Medium.svg#Lato-Medium') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
