.reklama {
    // .reklama__wrap
    &__wrap {
        @include x-container();
        margin: 30px auto 30px;
        @media(min-width:600px) {
            margin: 70px auto 70px;
        }
        @media(min-width:900px) {
            margin: 100px auto 100px;
        }
        @media(min-width:1200px) {
            margin: 100px auto calc(100px + 90px);
        }
    }
    // .reklama__row
    &__row {
        display: flex;
        margin: 0 -10px;
        @media(max-width:599px) {
            margin: 0;
            flex-direction: column;
        }
    }
    // .reklama__column
    &__column {
        padding: 0 10px;
        img {
            object-fit: cover;
        }
        @media(max-width:599px) {
            padding: 0;
            margin-bottom: 20px;
        }
    }
}
