.history {
    // .history__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto 95px;
    }
    // .history__h2
    &__h2 {
        @extend .h1;
        @extend .header-image;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 65px;
    }
    // .history__row
    &__row {
        display: flex;
        flex-wrap: wrap;
        @media(min-width:600px) {
            margin: 0 -12.5px;
            overflow: hidden;
        }
        div {
            flex: 1 1 100%;
            margin-bottom: 25px;
            @media(min-width:600px) {
                flex: 0 1 50%;
                padding: 0 12.5px;
            }
        }
    }
}
