.directions {
    // .directions__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
        padding-bottom: 40px;
    }
    // .directions__h2
    &__h2 {
        @extend .h1;
        @extend .header-image;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 40px;
    }
    // .directions__row
    &__row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
        overflow: hidden;
    }
    // .directions__column
    &__column {
        flex: 1 1 auto;
        padding: 0 10px;
        margin-bottom: 20px;
        @media(min-width:900px) {
            flex: 0 1 50%;
        }
    }
    // .directions__card
    &__card {
        position: relative;
        display: flex;
        height: 100%;
        padding: 25px 15px 50px 45px;
        color: $color-white;
        background: $color-blue;
        @media(min-width:500px) {
            &::after {
                content: "";
                position: absolute;
                right: 0;
                bottom: 0;
                z-index: 2;
                width: 141px;
                height: 100%;
                background: linear-gradient(to left top, $color-red 0%, $color-red 50%, transparent 50%);
            }
        }
    }
    // .directions__content
    &__content {
        flex: 1 1 300px;
        margin-right: 10px;
    }
    // .directions__logo
    &__logo {
        flex: 0 0 0;
        position: relative;
        z-index: 3;
        @media(min-width:500px) {
            flex: 0 0 130px;
        }
        @media(min-width:900px) {
            flex: 0 0 150px;
        }
        @media(min-width:1200px) {
            flex: 0 0 175px;
        }
    }
    // .directions__h3
    &__h3 {
        @extend .h2;
        @extend .header-image;
        margin-bottom: 25px;
    }
    // .directions__text
    &__text {
        @extend .text;
    }
    // .directions__btn
    &__btn {
        text-align: center;
        a {
            @extend .btn-transparent;
            @extend .offline__link_fill;
            transition: all 0.3s ease-in;
        }
    }
}
