@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-Regular.eot');
    src: url('Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-Regular.woff2') format('woff2'),
        url('Montserrat-Regular.woff') format('woff'),
        url('Montserrat-Regular.ttf') format('truetype'),
        url('Montserrat-Regular.svg#Montserrat-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-Medium.eot');
    src: url('Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-Medium.woff2') format('woff2'),
        url('Montserrat-Medium.woff') format('woff'),
        url('Montserrat-Medium.ttf') format('truetype'),
        url('Montserrat-Medium.svg#Montserrat-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-Bold.eot');
    src: url('Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-Bold.woff2') format('woff2'),
        url('Montserrat-Bold.woff') format('woff'),
        url('Montserrat-Bold.ttf') format('truetype'),
        url('Montserrat-Bold.svg#Montserrat-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-ExtraBold.eot');
    src: url('Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-ExtraBold.woff2') format('woff2'),
        url('Montserrat-ExtraBold.woff') format('woff'),
        url('Montserrat-ExtraBold.ttf') format('truetype'),
        url('Montserrat-ExtraBold.svg#Montserrat-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
