.not-found-page {
    position: relative;
    overflow: hidden;
    padding: 150px 0;
    &::after {
        content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTM0MSIgaGVpZ2h0PSI4MzEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEzNDAuNS04N3Y5ODcuMjU0SDQzMi44MzNjMzcuNjczLTc1LjYzNCA5Ny4zODctMTQwLjMwMiAxNzIuMDg1LTE4MC4wMSA0Mi41MzUtMjIuNjEyIDg4LjczMy0zNy41NDcgMTMxLjk3NC01OC43OCA0My4yNDUtMjEuMjI4IDg0LjgyNy01MC4yNzggMTA4LjExNC05Mi40NDkgMjMuMjg5LTQyLjE3MSAyMy43NjgtOTkuNTk1LTguOTc1LTEzNC45My0zMS43ODctMzQuMzA0LTgzLjc5LTM5LjM3LTEzMC41MjgtMzcuNjc5LTQ2LjczNiAxLjY4Ny05NS45MjMgNy40MzItMTM4LjI1NS0xMi40NDktNzcuNjI2LTM2LjQ1NC05Ni4wMzItMTM4LjYzNS0xNTUuMjM2LTIwMC42ODEtNDEuMDUyLTQzLjAyLTk5LjU1OS02NC4yNjEtMTU2Ljc5Ny04MC4zNzQtNTcuMjQtMTYuMTE0LTExNi42MDQtMjguOTg5LTE2Ny4yNjQtNjAuMTI3QzQxLjY5IDE0LjMzOCAzLjIzNi0zMy42OTUgMC04N2gxMzQwLjV6IiBmaWxsPSIjRUJGNkZGIi8+PC9zdmc+');
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;
    }
    // .not-found-page__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
    }
    // .not-found-page__row
    &__row {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;
    }
    // .not-found-page__info
    &__info {
        flex: 0 1 50%;
        text-align: center;
        @media(max-width:1199px) {
            margin-right: 30px;
        }
        @media(max-width:767px) {
            margin-right: 0px;
        }
    }
    // .not-found-page__image
    &__image {
        flex: 1 1 50%;
        position: relative;
        @media(max-width:1199px) {
            flex: 0 1 auto;
            display: flex;
            justify-content: flex-end;
        }
    }
    // .not-found-page__title
    &__title {
        margin-bottom: 45px;
        font-family: $font_family_Montserrat;
        font-size: 144px;
        line-height: calc(176 / 144 * 100%);
        letter-spacing: 1px;
        font-weight: 700;
        text-transform: uppercase;
        color: #021632;
    }
    // .not-found-page__subtitle
    &__subtitle {
        margin-bottom: 45px;
        font-family: $font_family_Lato;
        font-size: 48px;
        line-height: calc(57 / 48 * 100%);
        font-weight: 700;
        letter-spacing: 1px;
        color: $color-red;
    }
    // .not-found-page__text
    &__text {
        @extend .h2;
        margin-bottom: 50px;
        font-weight: 700;
        color: $color-blue;
    }
    // .not-found-page__btn
    &__btn {
        @extend .why__btn;
        text-transform: uppercase;
    }
    // .not-found-page__conus
    &__conus {
        @media(max-width:767px) {
            display: none;
        }
        @media(min-width:1200px) {
            margin-right: 70px;
        }
    }
    // .not-found-page__conus-rotate
    &__conus-rotate {
        position: absolute;
        top: 25%;
        right: 0;
        transform: rotate(110deg);
        @media(max-width:1199px) {
            display: none;
        }
    }
}
