// fonts
$font_family_Montserrat: "Montserrat";
$font_family_Lato: "Lato";

// colors
$color-black: #000000;
$color-white: #ffffff;
$color-red: #d7182a;
$color-blue: #0066b3;
$color-gray: #d8d8d8;
$color-light_gray: #f8f8f8;
$color-dark_gray: #9f9f9f;
