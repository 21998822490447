.why {
    // .why__wrap
    &__wrap {
        @include x-container();
        margin: 50px auto 65px;
    }
    // .why__row
    &__row {
        display: flex;
        @media(max-width:899px) {
            flex-wrap: wrap;
        }
        @media(min-width:900px) {
            margin: 0 -12px;
            overflow: hidden;
        }
    }
    // .why__column
    &__column {
        flex: 1 1 100%;
        @media(min-width:900px) {
            flex: 0 1 50%;
            padding: 0 12px;
            margin-bottom: 15px;
        }
        // .why__column_image
        &_image {
            position: relative;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            @media(max-width:899px) {
                padding-bottom: 40%;
                margin-bottom: 20px;
            }
            @media(max-width:599px) {
                padding-bottom: 65%;
            }
            @media(min-width:900px) {
                img {
                    left: 12px;
                    width: calc(100% - 24px);
                }
            }
        }
        // .why__column_description
        &_description {
            *:last-child {
                margin-bottom: 0;
            }
        }
    }
    // .why__h1
    &__h1 {
        @extend .h1;
        @extend .header-image;
        margin-bottom: 30px;
        @media(max-width:899px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }
    }
    // .why__text
    &__text {
        @extend .text;
        margin-bottom: 30px;
    }
    // .why__btn
    &__btn {
        @extend .btn-transparent;
        border-color: $color-blue;
        color: $color-blue;
        transition: all 0.3s ease-in;
        &:hover {
            background-color: $color-blue;
            color: $color-white;
        }
    }
}
