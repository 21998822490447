.block-text {
    // .block-text__wrap
    &__wrap {
        @extend .history__wrap;
    }
    // .block-text__h2
    &__h2 {
        @extend .history__h2;
    }
    // .block-text__content
    &__content {
        @extend .admin;

        .h2 {
            position: relative;
            text-align: center;
            &::after {
                content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOTYiIGhlaWdodD0iMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsPSIjMDA2NmIzIiBkPSJNMCAwaDUwLjY0djNIMHptNTUuNDMgMGgyOC43NDF2M0g1NS40M3ptMzYuMjY5IDBoNC4xMDZ2M2gtNC4xMDZ6Ii8+PC9zdmc+');
                position: absolute;
                bottom: -15px;
                left: 50%;
                transform: translateX(-50%)
            }
        }

    }
}
