.request {
    padding: 50px 0;
    background: $color-light_gray;
    // .request__wrap
    &__wrap {
        @include x-container();
        position: relative;
        margin: 0 auto;
    }
    // .request__h2
    &__h2 {
        @extend .h1;
        margin-bottom: 20px;
        text-align: center;
    }
    // .request__text
    &__text {
        @extend .text;
        margin-bottom: 40px;
        text-align: center;
    }
    // .request__form
    &__form {
    }
    // .request__success
    // .request__success-first
    &__success,
    &__success-first {
        display: none;
        position: absolute;
        padding: 50px 15px;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background: $color-light_gray;

        .request__h2 {
            @extend .header-image;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    // .request__show
    &__show {
        display: flex;
        align-items: center;
        p {
            @extend .text;
            margin-right: 10px;
        }
    }
    // .request__social-box
    &__social-box {
        .social-box__link {
            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }
}
.form-request {
    // .form-request__fieldset
    &__fieldset {
        @extend .fieldset;
        @media(min-width:600px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media(min-width:900px) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &_one-row{
        grid-template-columns: repeat(1, 1fr);
    }

    .form-field {
        @extend .fieldset-field;

        &.question {
            grid-row: span 2;
            @media(min-width:600px) {
                grid-column: span 2;
            }
            @media(min-width:900px) {
                grid-column: 3;
                grid-row: 1 / span 2;
            }
        }
    }
    // .form-request__label-checkbox
    &__label-checkbox {
        position: relative;
        display: inline-flex;
        margin-bottom: 30px;
        cursor: pointer;
        span {
            display: inline-block;
            font-size: 14px;
            line-height: calc(17 / 14 * 100%);
            &::before, &::after {
                content: '';
                position: absolute;
            }
            &::before {
                top: 0;
                left: 0;
                width: 16px;
                height: 16px;
                border: 1px solid $color-blue;
                border-radius: 50%;
            }

        }
        a {
            color: $color-blue;
            text-decoration: underline;
            transition: all 0.3s ease-in;
            &:hover {
                color: $color-red;
            }
        }
    }
    // .form-request__checkbox
    &__checkbox {
        flex: 0 0 15px;
        height: 15px;
        margin-right: 12px;
        appearance: none;
        &:checked ~ span {
            &::after {
                top: 3px;
                left: 3px;
                width: 10px;
                height: 10px;
                background: $color-blue;
                border-radius: 50%;
            }
        }
    }
    // .form-request__btn
    &__btn {
        @extend .btn-transparent;
        display: flex;
        margin: 0 auto;
        border-color: $color-red;
        color: $color-red;
        transition: all 0.3s ease-in;
        &:hover {
            background-color: $color-red;
            color: $color-white;
        }
    }
// когда форма отправлена
    &.success {
        visibility: hidden;
        pointer-events: none;
        & ~ .request__success {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            @extend .h2;
        }
        &.first {
            & ~ .request__success-first {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                @extend .h2;
            }
        }
    }
}
