.telegram {
    position: fixed;
    bottom: 0px;
    width: 100%;
    padding: 20px 0;
    background: $color-red;
    z-index: 10;
    display: none;
    // .telegram__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
    }
    // .telegram__row
    &__row {
        position: relative;
        .icon-closer {
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            color: $color-white;
        }
        @media(min-width:768px) {
            display: flex;
        }
    }
    // .telegram__image
    &__image {
        display: none;
        .icon-sound {
            width: 115px;
            height: 115px;
        }
        @media(min-width:768px) {
            display: block;
            flex: 0 0 115px;
            margin-right: 20px;
        }
        @media(min-width:1200px) {
            margin-right: 80px;
        }
    }
    // .telegram__content
    &__content {
        @media(min-width:768px) {
            flex: 1 1 auto;
            margin-right: 45px;
        }
        @media(min-width:900px) {
            display: flex;
        }
    }
    // .telegram__info
    &__info {
        @media(max-width:899px) {
            margin-bottom: 30px;
        }
        @media(min-width:900px) {
            flex: 0 1 545px;
            margin-right: 50px;
        }
        color: $color-white;
        @media(min-width:1200px) {
            margin-right: 110px;
        }
    }
    // .telegram__subscribe
    &__subscribe {
        flex: 1 1 auto;
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        .icon-telegram-banner {
            margin-right: 32px;
        }
    }
    // .telegram__h2
    &__h2 {
        @extend .h2;
        margin-bottom: 20px;
        @media(max-width:399px) {
            font-size: 20px;
            margin-right: 50px;
        }
    }
    // .telegram__text
    &__text {
        @extend .text;
        font-size: 16px;
    }
    // .telegram__btn
    &__btn {
        @extend .btn-transparent;
        min-width: 195px;
        font-size: 14px;
        color: $color-blue;
        background-color: $color-white;
        border-color: $color-white;
        box-shadow: 5px 8px 5px rgba($color: $color-white, $alpha: 0.4);
        transition: all 0.3s ease-in;
        &:hover {
            background: transparent;
            color: $color-white;
        }
    }
}
